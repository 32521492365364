<template>
    <b-card  no-body>
      <template #header>
        <h6>INVENTORY
          <router-link to="/inventory/form">
          <span class="float-right"
            ><b-button v-b-modal.modal-1 variant="primary"
              ><i class="las la-plus"></i> Add New</b-button
            ></span
          ></router-link
        >
        </h6>
        
      </template>

  <div class="card card-custom gutter-b card-stretch card-shadowless">
        <!-- User Interface controls -->

        <div class="card-body">
      <DataTable
        ref="dt"
        :value="items"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="filters"
        class="mt-0"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
      >
       <template #header>
            
                  <b-form-group>
                    <b-row>
                     
                      <b-col md="6" lg="3">
                        <label for="search">Search</label>
                        <b-input-group size="md" class="mb-2">
                          <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                          </b-input-group-prepend>
                          <b-form-input
                            type="search"
                            placeholder="Search"
                            class="rounded-sm"
                            v-model="filters['global']"
                          >
                          </b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
        
            </template>

        <Column style="width: 3rem" field="sl"  header="#" :exportable="false" > <template #body="slotProps">
            {{ slotProps.index+1 }}
          </template></Column>
        <Column
          field="Image"
          header="image"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="">
            <img src="/img/frame-width.svg" alt="..." width="80" class="img-fluid img-thumbnail">
          </template>
        </Column>
        <Column
          field="name"
          header="Name"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>
        <Column
          field="code"
          header="Code"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.code }}
          </template>
        </Column>
        <Column
          field="status"
          header="Status"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.status }}
          </template>
        </Column>
        <Column
          field="action"
          header="Action"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #body="slotProps">
            <router-link class="svg-icon svg-icon-md svg-icon-primary" :to="`/inventory/form/${slotProps.data.id}`">
                <span class="svg-icon svg-icon-md svg-icon-primary"><svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Communication-/-Write" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                        <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" id="Path-11" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>
                        <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    </g>
                </svg></span>
            </router-link>
          </template>
        </Column>
      </DataTable>
        </div>
  </div>
    </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  components: {
    DataTable,
    Column,

  },
  data() {
    return {
      filters: {},
      items: [
        {
          id:1,
          name: "Test",
          code:"ABCD",
          status:"A"
        },
        {
          id:2,
          name: "Random",
          code:"GFJ",
          status:"B"
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Inventory",
      },
    ]);
  },
};
</script>

<style></style>
